<template>
  <div class="d-flex flex-wrap justify-space-between" v-if="isLoad">
    <v-col cols="12" md="4" v-for="n in 8" :key="n">
      <v-skeleton-loader
        width="374"
        height="490"
        :key="n.id"
        v-bind="attrs"
        type="card-avatar, article, actions"
      ></v-skeleton-loader>
    </v-col>
  </div>
</template>

<script>
export default {
  name: 'Preloader',
  props: {
    isLoad: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      attrs: {
        boilerplate: false,
        elevation: 4
      }
    }
  }
}
</script>

<style scoped>

</style>
