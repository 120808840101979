<template>
  <div class="mt-12">
    <div class="d-flex">

      <sidebar-section
        @loadEvent="isLoad = !isLoad"
        @loadFilter="changeFilter">
      </sidebar-section>

      <preloader
        :isLoad="isLoad">
      </preloader>

      <cards-section
        :isLoad="isLoad">
      </cards-section>

    </div>
    <pagination
      @loadEvent="isLoad = !isLoad"
      :filter="filter"
      :lastPage="lastPage">
    </pagination>
  </div>
</template>

<script>
import SidebarSection from '@/components/main/MainPage/OurPsychologists/SidebarSecition'
import CardsSection from '@/components/main/MainPage/OurPsychologists/CardsSection'
import Preloader from '@/components/main/MainPage/OurPsychologists/Preloader'
import Pagination from '@/components/main/MainPage/OurPsychologists/Pagination'
export default {
  name: 'ConnectComponent',
  components: {
    Pagination,
    Preloader,
    SidebarSection,
    CardsSection
  },
  data () {
    return {
      lastPage: null,
      isLoad: false,
      filter: ''
    }
  },
  beforeMount () {
    this.isLoad = true
    this.$store.dispatch('user/getPsychologistFilter')
    this.$store.dispatch('user/getPsychologists', { page: 1, filter: '' })
      .then((response) => {
        this.lastPage = response.data.meta.last_page
        this.isLoad = false
      })
  },
  methods: {
    changeFilter (data) {
      this.filter = data.id
      this.lastPage = data.lastPage
      this.isLoad = !this.isLoad
    }
  }

}
</script>

<style scoped>

</style>
