<template>
  <div class="d-flex aside-bar">
    <v-col>
      <div
        class="d-flex flex-column flex-wrap"
        v-for="(items, i) in filters"
        :key="i"
      >
        <h3>
          {{ $vuetify.lang.t(`$vuetify.view.psychologists.specialization.${i}`) }}
        </h3>
        <template v-for="(item, i)  in items">
          <v-chip
            :key="i"
            class="mt-2"
            :class="{activeColor: filter === i}"
            @click="handleFilter(i)"
          >
            {{ item}}
          </v-chip>
        </template>
      </div>
    </v-col>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  data () {
    return {
      filter: null
    }
  },
  methods: {
    handleFilter (id) {
      this.filter = id
      this.$emit('loadEvent')
      this.$store.dispatch('user/getPsychologists', { page: 1, filter: id })
        .then((response) => {
          this.$emit('loadFilter', { id: id, lastPage: response.data.meta.last_page })
        })
    }
  },
  computed: {
    filters () {
      return this.$store.getters['user/getFiltersList']
    }
  }
}
</script>

<style lang="scss" scoped>
.activeColor {
  color: #ffffff !important;
  background-color: #59b6b9 !important;
  font-weight: 800;
  pointer-events: none;
}
</style>
