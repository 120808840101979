<template>
  <div
    class="d-flex flex-wrap justify-space-around full-width"
    v-if="!isLoad"
  >
    <template v-for="item in psychologists">
      <v-card
        :key="item.id"
        v-if="!isLoad"
        class="card-custom mx-auto my-12 d-flex justify-space-between flex-column"
        max-width="374"
      >
        <v-img
          class="ma-5 border-rounded align-self-center"
          max-height="250"
          width="250"
          :src="item.photo"
        ></v-img>
        <div class="wrapper-title">
          <v-card-title ref="name" class="align-self-start">{{
              item.name + " " + item.surname
            }}</v-card-title>
        </div>
        <v-card-text>
          <div class="my-4 text-subtitle-1">
            {{ $vuetify.lang.t("$vuetify.view.psychDescrModal.experiense") + item.experience_ages }}
          </div>

          <div>
            {{ item.about_info | shorten(90) }}
            <showPsyhDescriptionModal
              :user="{ userName: item.name, aboutInfo: item.about_info }"
            >{{
                $vuetify.lang.t("$vuetify.view.psychDescrModal.showAll")
              }}</showPsyhDescriptionModal
            >
          </div>
        </v-card-text>

        <v-divider class="mx-4 mt-auto"></v-divider>

        <v-card-actions class="justify-end">
          <v-btn class="book-btn" text @click="reserve(item.id)">{{
              $vuetify.lang.t("$vuetify.view.psychDescrModal.book")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </div>
</template>

<script>
import showPsyhDescriptionModal from '@/components/UI/showPsyhDescriptionModal'
export default {
  name: 'Cards',
  props: {
    isLoad: {
      type: Boolean,
      required: true
    }
  },
  components: {
    showPsyhDescriptionModal
  },
  data () {
    return {
      linkToDashboard: process.env.VUE_APP_DASHBOARD + '/patientRegForm?chosen_psychologist='
    }
  },
  updated () {
    console.log('updated')
    const arr = Array.from(this.$refs.name)
    arr.forEach(e => {
      if (e.clientHeight > 64 && e.clientHeight < 100) {
        e.classList.add('title-text-md')
      } else if (e.clientHeight > 100) {
        e.classList.add('title-text-lg')
      }
    })
  },
  computed: {
    psychologists () {
      return this.$store.getters['user/getPsychologists']
    }
  },
  methods: {
    reserve (id) {
      this.$swal({
        text: 'Психолог забронирован. Пройдите авторизацию',
        icon: 'success',
        timer: 4000,
        toast: true,
        button: 'OK'
      }).then(() => {
        window.location.href = this.linkToDashboard + id
      })
    }
  },
  filters: {
    shorten: (val, letters = 1) => val.split('').slice(0, letters).join('')
  }
}
</script>

<style lang="scss" scoped>
.border-rounded {
  border-radius: 50% !important;
}
.full-width {
  width: 100%;
}
.book-btn {
  color: $lightColor !important;
}
.card-custom {
  max-height: 600px;
  overflow: hidden;
}
.wrapper-title {
  &:hover .title-text-md {
    transform: translateX(-60%);
  }
  &:hover .title-text-lg {
    transform: translateX(-100%);
  }
}
.title-text-md, .title-text-lg {
  white-space: nowrap;
  transition: 1.5s;
}
</style>
