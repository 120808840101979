<template>
<ConnectComponent></ConnectComponent>
</template>

<script>
import ConnectComponent from '@/components/main/MainPage/OurPsychologists/ConnectComponent'
export default {
  name: 'Psychologists',
  components: { ConnectComponent },
  metaInfo () {
    return {
      title: 'Перевірені професійні психологи України з досвідом',
      meta: [
        {
          vmid: 'og:description',
          property: 'og:description',
          name: 'description',
          content: 'Досвідчені психологи та психотерапевти. Лише вища \n' +
            'психологічна освіта. Понад 3 роки практики. Онлайн'
        },
        {
          name: 'keywords',
          content: 'психолог онлайн, психолог онлайн Україна, сімейний \n' +
            'психолог, дитячий психолог, підлітковий психолог, сексолог, \n' +
            'психотерапевт, психотерапія, гештальт терапія, \n' +
            'транзакційний аналіз, психоаналіз'
        }
      ]
    }
  }

}
</script>

<style scoped>

</style>
