<template>
  <v-row justify="center" class="mt-8 mb-8">
    <div class="text-center">
      <v-pagination
        @next="page + 1"
        @previous="page - 1"
        v-model="page"
        :length="lastPage"
        :total-visible="8"
      ></v-pagination>
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    lastPage: {
      type: Number
    },
    filter: {
      type: String
    }
  },
  data () {
    return {
      page: 1
    }
  },
  watch: {
    page () {
      this.$emit('loadEvent')
      this.$store.dispatch('user/getPsychologists', { page: this.page, filter: this.filter })
        .then((response) => {
          this.$emit('loadEvent')
          this.lastPage = response.data.meta.last_page
        })
    },
    filter () {
      this.page = 1
    }
  }
}
</script>

<style scoped>

</style>
