<template>
  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" class="show-all" v-on="on"><slot></slot></span>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{user.userName}}
      </v-card-title>
      <v-card-text>
        <v-textarea
          class="textarea"
          v-model="user.aboutInfo"
          auto-grow
          solo
          flat
          readonly
        ></v-textarea>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="show-all" text @click="dialog = false">{{
            $vuetify.lang.t("$vuetify.view.psychDescrModal.close")
          }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'showPsyhDescriptionModal',
  data () {
    return {
      dialog: false
    }
  },
  props: ['user']
}
</script>
<style lang="scss" scoped>
.show-all {
  color: $lightColor !important;
}
.textarea #input-199::after {
  content: none !important;
}
</style>
